
import { defineComponent, onBeforeMount, ref } from "vue";
import {
  IonPage,
  IonContent,
  IonItem,
  IonLabel,
  IonInput,
  IonText,
  IonButton,
  toastController,
} from "@ionic/vue";
import axios from "axios";

import { logoGoogle } from "ionicons/icons";
import { useRouter } from "vue-router";
import router from "@/router";

export default defineComponent({
  name: "Login",
  ionViewWillEnter() {
    const userToken = window.$cookies.get("accessToken");
    if (userToken) {
      this.$router.replace("/main/home");
    }
  },
  setup() {
    const googleSignup = ref(null);
    const profileGoogle = ref();
    const router = useRouter();

    onBeforeMount(() => {
      const googleLoginScript = document.createElement("script");
      googleLoginScript.setAttribute(
        "src",
        "https://apis.google.com/js/platform.js?onload=init"
      );
      googleLoginScript.setAttribute("defer", "");
      googleLoginScript.setAttribute("async", "");
      document.head.appendChild(googleLoginScript);
    });

    window.init = () => {
      window.gapi.load("auth2", function () {
        const auth2 = window.gapi.auth2.init({
          client_id:
            "441216217834-253jql0ndi2nevmr2sl0k7124cijb3mc.apps.googleusercontent.com",
          client_secret: "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",
        });
        auth2.attachClickHandler(
          document.getElementById("googleSignup"),
          {},
          async function (googleUser) {
            const profile = googleUser.getBasicProfile();

            const formdata = new FormData();
            formdata.append("email", profile.getEmail());
            formdata.append("nama", profile.getName());

            await axios
              .post("login-google.php", formdata)
              .then(({ data }) => {
                const token = data.data.accessToken;
                const date = data.data.expiry;
                const newDate = new Date(date);
                newDate.setHours(newDate.getHours() + 7);
                window.$cookies.config(newDate.toUTCString());
                window.$cookies.set("accessToken", token);

                router.replace("/main/home");
              })
              .catch(async ({ response }) => {
                const toast = await toastController.create({
                  message: response.data.message,
                  duration: 2000,
                });
                return toast.present();
              });
            profileGoogle.value = googleUser;
          },
          async function (error) {
            if (error.error == "popup_closed_by_user") return;
            const toast = await toastController.create({
              message: error.message,
              duration: 2000,
            });
            return toast.present();
          }
        );
      });
    };

    return { logoGoogle, googleSignup, profileGoogle };
  },
  data() {
    return {
      btnLoading: false,
      email: "",
      password: "",
    };
  },
  methods: {
    async onLogin() {
      this.btnLoading = true;
      if (!this.email && !this.password) {
        return;
      }

      const formData = new FormData();
      formData.append("email", this.email);
      formData.append("password", this.password);

      await axios
        .post("/login", formData)
        .then(({ data }) => {
          this.btnLoading = false;
          const token = data.data.accessToken;
          if (token) {
            const date = data.data.expiry;
            const newDate = new Date(date);
            newDate.setHours(newDate.getHours() + 7);
            window.$cookies.config(newDate.toUTCString());
            window.$cookies.set("accessToken", token);
            this.$router.replace("/main/home");
          }
        })
        .catch(async ({ response }) => {
          this.btnLoading = false;
          this.password = "";
          const toast = await toastController.create({
            message: response.data.message,
            duration: 2000,
          });
          return toast.present();
        });
    },
  },
  components: {
    IonPage,
    IonContent,
    IonItem,
    IonLabel,
    IonInput,
    IonText,
    IonButton,
  },
});
